<template>
  <div id="terms-and-conditions">
    <v-container>
      <v-row class="top-bar">
        <v-col cols="10"></v-col>
        <v-col cols="2" class="text-right">
          <v-btn
            icon
            class="menu-btn"
            @click="showNavDrawer = true"
          >
            <v-icon size="18">
              {{menu_icon}}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <div class="my-page-content">
        <v-row>
          <v-col cols="12">
            <div class="title-8">
              Όροι και προϋποθέσεις παραγγελίας μέσω της Wecook ιστοσελίδας ή τηλεφωνικής παραγγελίας.
            </div>
          </v-col>

          <v-col cols="12">
            <ul class="body-1">
              <li>Αλλαγή ή ακύρωση παραγγελίας μετά την αποστολή δεν γίνεται.</li>
              <li>Εξαργυρώνονται στις online παραγγελίες μόνο οι εκπτώσεις και κουπόνια που αναγράφονται στα σχετικά έντυπα ή όταν έχει ανακοινωθεί ειδικά από την εταιρεία.</li>
              <li>Οι παραγγελίες μεγάλης αξίας καθώς και τα στοιχεία επικοινωνίας των πελατών που παραγγέλνουν για πρώτη φορά ενδέχεται να επιβεβαιωθούν τηλεφωνικά. Για τον λόγο αυτό παρακαλούμε να αναγράφετε στην online παραγγελία σας το τηλέφωνο της διεύθυνσης παράδοσης ώστε να έχουμε τη δυνατότητα να σας καλέσουμε για επιβεβαίωση.</li>
              <li>Η εταιρεία δεν μπορεί να εγγυηθεί την αδιάκοπη λειτουργία του ιστοτόπου wecook.gr και ουδεμία ευθύνη φέρει σχετικά με οποιαδήποτε τεχνική ή όποια άλλη αδυναμία χρήσης της ιστοσελίδας και του app.</li>
              <li>Τα Tips για τους οδηγούς δεν επιστρέφονται για παραγγελίες που έχουν παραδοθεί.</li>
              <li>Σύμφωνα με τον νόμο 4496/2017 που εισήγαγε την Οδηγία 2015/720ΕΕ, επιβάλλεται από την 1η Ιανουαρίου 2018 περιβαλλοντικό τέλος για τις λεπτές πλαστικές σακούλες μεταφοράς. Αποτελεί μέρος της εναρμόνισης της Ελληνικής Νομοθεσίας με Ευρωπαϊκή Οδηγία. Ο παραπάνω νόμος ορίζει περιβαλλοντικό τέλος 0,07258€ συν ΦΠΑ 24%. Λόγω της ελάχιστης υποδιαίρεσης του ευρώ (0,01€) η συνολική χρέωση δεν μπορεί να είναι μικρότερη από 0,09€ ανά σακούλα. Η ενημέρωση για το κόστος πλαστικής σακούλας βρίσκεται στην επιλογή «Επιβεβαίωση Παραγγελίας» ως «Σακούλα πλαστική (περιβαλλοντικό τέλος)» μαζί με τη συνολική ενημέρωση συνολικό κόστος της παραγγελίας.</li>
              <li>Η παράδοση των παραγγελιών θα γίνεται μεσα στο άνοιγμα 1 ώρας που έχετε διαλέξει κατά την διαδικασία παραγγελίας.</li>
              <li> H Wecook δεν φέρει καμία ευθύνη αν ο χρήστης δεν έχει επικοινωνήσει για τυχόν αλλεργίες ή προβλήματα υγείας.</li>
              <li>H Wecook έχει υποχρέωση να παρέχει πληροφορίες για τα διαθέσιμα πιάτα, τις διατροφικές ανάγκες (π.χ. χορτοφάγος, χωρίς γλουτένη), τις τιμές και τον χρόνο παράδοσης.</li>
            </ul>
          </v-col>

          <v-col cols="12" class="mt-5">
            <div class="title-8">
              Πολιτική Απορρήτου και Δήλωση Προστασίας Προσωπικών Δεδομένων
            </div>
          </v-col>

          <v-col cols="12">
            <div class="body-1">
              Στη Wecook σεβόμαστε τα προσωπικά δεδομένα του κάθε επισκέπτη της ιστοσελίδας wecook.gr. Το κείμενο αυτό (το οποίο ονομάζεται Πολιτική Απορρήτου και Δήλωση Προστασίας Προσωπικών Δεδομένων – εφεξής η «Δήλωση») εξηγεί με απλά λόγια, αλλά και με σαφήνεια πώς επεξεργαζόμαστε τα προσωπικά δεδομένα τα οποία συλλέγουμε από εσάς ή τα οποία εσείς μας παρέχετε. Παρακαλούμε να μελετήσετε προσεκτικά την παρούσα Δήλωση προκειμένου να ενημερωθείτε σχετικά με τον τρόπο που συλλέγουμε, αποθηκεύουμε, χρησιμοποιούμε, διαβιβάζουμε και προστατεύουμε τις πληροφορίες/προσωπικά δεδομένα που λαμβάνουμε από εσάς:
            </div>
            <div class="title-12 mt-5 mb-2">Γενικά:</div>
            <ol class="body-1">
              <li>Η Wecook συλλέγει και τηρεί αποκλειστικά και μόνο τα προσωπικά δεδομένα που εσείς της γνωστοποιείτε με την εισαγωγή των αιτουμένων προσωπικών πληροφοριών στα αντίστοιχα πεδία ή/και των απαντήσεών σας στο πλαίσιο συγκεκριμένων ενεργειών που διεξάγει η εταιρια και οικειοθελώς δύνασθε να συμμετέχετε και είναι τα ελάχιστα απαραίτητα για σωστή λειτουργία της.</li>
              <li>Στα πλαίσια της υποχρέωσης διαφύλαξης και προστασίας των πληροφοριών των πελατών της, η Wecook δεσμεύεται να προστατεύει και να χρησιμοποιεί κατά τον αρμόζοντα τρόπο τις προσωπικές πληροφορίες (εφεξής "προσωπικά δεδομένα") που συλλέγονται είτε διαδικτυακά από τον παρόντα δικτυακό τόπο είτε μέσω της επικοινωνίας/συνεργασίας με κάθε τρίτο.
                <div class="body-1">
                  Στο πλαίσιο της διαδικασίας εγγραφής, συλλέγουμε και επεξεργαζόμαστε τα παρακάτω στοιχεία:
                </div>
                <ul class="body-1">
                  <li>Όνομα</li>
                  <li>Ηλεκτρονική διεύθυνση</li>
                  <li>Διεύθυνση παραγγελίας</li>
                  <li>IP</li>
                  <li>Τηλέφωνο (σταθερό)</li>
                  <li>Τηλέφωνο (κινητό)</li>
                  <li>Φορολογικά στοιχεία (σε περίπτωση τιμολογίου)</li>
                </ul>
              </li>
              <li>Η Wecook συλλέγει μόνον τα προσωπικά δεδομένα που παρέχονται οικειοθελώς από εσάς, τους διαδικτυακούς μας επισκέπτες, έτσι ώστε να ικανοποιήσουμε τα εκάστοτε αιτήματά σας, να μπορούμε να σας παρέχουμε ενημέρωση για τα προϊόντα και τις υπηρεσίες μας και για να σας εξυπηρετούμε κατά τον βέλτιστο τρόπο. Όπου αναζητούνται πρόσθετες, προαιρετικές πληροφορίες, θα ενημερωθείτε σχετικά κατά τη στιγμή της συλλογής τους.</li>
            </ol>
          </v-col>

          <v-col cols="12">
            <div class="title-8 mb-2">
              Ειδικότερα:
            </div>
            <ol class="body-1">
              <li>
                <div class="title-8">Συλλογή και σκοποί χρήσης των προσωπικών δεδομένων</div><br>
                <p>Η λήψη των προσωπικών δεδομένων πραγματοποιείται μόνο εφόσον εσείς επιλέξετε αυτοβούλως να τα παρέχετε — για παράδειγμα, εάν επικοινωνήσετε με email ή αν εγγραφείτε ως πελάτες των προϊόντων και υπηρεσιών της Wecook. Η χρήση των προϊόντων και υπηρεσιών που παρέχονται από την ιστοσελίδα συμπεριλαμβανομένης και της παροχής υπηρεσιών online παραγγελίας των προϊόντων της Wecook προϋποθέτει τη διασφάλιση της δυνατότητας επικοινωνίας μαζί σας. Συνεπώς, είναι απαραίτητο κατά την εγγραφή σας στο διαδικτυακό τόπο και στην εφαρμογή να δηλώσετε τα αληθή προσωπικά δεδομένα που θα σας ζητηθούν, όπως προσδιορίζονται ανωτέρω.</p>

                <p>Με την εγγραφή σας στις υπηρεσίες που παρέχονται από την εταιρια Wecook συναινείτε επίσης στην αποθήκευση και χρήση των προσωπικών δεδομένων σας, σύμφωνα με την παρούσα Δήλωση. Επιτρέπεται να επεξεργαζόμαστε τα προσωπικά σας δεδομένα, προκειμένου να παρέχουμε προσωποποιημένες υπηρεσίες, βάσει του νόμου (άρθρο 6(1β) του Κανονισμού (ΕΕ) 2016/679). Τα προσωπικά σας δεδομένα δεν χρησιμοποιούνται για άλλους σκοπούς, πλην των όσων περιγράφονται στη Δήλωση, εκτός αν λάβουμε την προηγούμενη άδειά σας, ή εκτός αν κάτι τέτοιο απαιτείται ή επιτρέπεται από το νόμο.</p>

                <p>Σκοπός της συλλογής, χρήσης και επεξεργασίας των προσωπικών σας δεδομένων , αποτελεί (α) η παροχή των υπηρεσιών και των προϊόντων που αιτείστε μέσω του website αλλα και της εφαρμογής, για την λήψη των οποίων απαιτείται η χρήση ή/ και η επεξεργασία τους π.χ. online παραγγελίες και η συνακόλουθη υλοποίηση των συμβατικών υποχρεώσεων, στα πλαίσια εκτέλεσης, παράδοσης και τιμολόγησης παραγγελιών υπό τις βέλτιστες συνθήκες και με τον αποτελεσματικότερο τρόπο, (β) η προώθηση των προϊόντων και των υπηρεσιών που παρέχονται από την Wecook ιδιαίτερα μέσω του wecook.gr αλλα και της εφαρμογης (γ) η παροχή εξατομικευμένων υπηρεσιών και η διευκόλυνση της διαδικασίας παραγγελίας, με σκοπό την ευκολία του χρήστη και / ή η διασφάλιση ότι το περιεχόμενο του δικτυακού τόπου και της εφαρμογής παρουσιάζεται με τον πιο αποδοτικό τρόπο για εσάς και τον υπολογιστή σας.</p>

                <p>Η Wecook συλλέγει «ευαίσθητα» προσωπικά δεδομένα μόνο όταν εσείς μας προσφέρετε οικειοθελώς αυτά τα δεδομένα ή όταν αυτά τα δεδομένα απαιτούνται ή η συλλογή τους επιτρέπεται από το νόμο. Σας συμβουλεύουμε να απόσχετε από την παροχή ευαίσθητων δεδομένων, εκτός εάν αυτό απαιτείται για το σκοπό της παροχής των προσωπικών δεδομένων ή αν δια της παρούσας συναινείτε στη χρήση από την Wecook αυτών των δεδομένων για τους σκοπούς της.</p>

                <p>Η Wecook μπορεί να ζητήσει από εσάς να της παρέχετε ορισμένα προσωπικά δεδομένα για την αποστολή ενημερωτικών μηνυμάτων σχετικά με τα προϊόντα και τις υπηρεσίες ή/και σχετικών προσφορών και ανακοινώσεων. Η Wecook μπορεί επίσης να ζητά την άδειά σας για ορισμένες χρήσεις των προσωπικών σας δεδομένων και μπορείτε είτε να συναινέσετε ή να αρνηθείτε αυτές τις χρήσεις. Εάν επιθυμείτε συγκεκριμένες υπηρεσίες ή επικοινωνίες, όπως ένα ηλεκτρονικό ενημερωτικό φυλλάδιο (newsletter), θα έχετε τη δυνατότητα να διαγραφείτε από τον σχετικό κατάλογο παραληπτών οποτεδήποτε ακολουθώντας τις οδηγίες που περιέχονται σε κάθε επικοινωνία. Εάν αποφασίσετε να διαγραφείτε από κάποια υπηρεσία ή επικοινωνία, θα προσπαθήσουμε να διαγράψουμε τα δεδομένα σας το συντομότερο δυνατό, παρόλο που μπορεί να χρειαστούμε ορισμένο χρόνο ή/και πληροφορίες πριν μπορέσουμε να επεξεργαστούμε το αίτημά σας.</p>
              </li>
              <li>
                <div class="title-8">Συλλογή και σκοποί χρήσης των προσωπικών δεδομένων</div><br>
                <p>Εφόσον το επιθυμείτε, μπορείτε να αιτηθείτε οποτεδήποτε να ενημερωθείτε σχετικά με τα προσωπικά σας δεδομένα που τηρούνται από την Wecook, τους αποδέκτες τους, το σκοπό της τήρησης και της επεξεργασίας τους καθώς και την τροποποίηση, διόρθωση ή τη διαγραφή τους, αποστέλλοντας σχετικό ηλεκτρονικό μήνυμα στην διεύθυνση info@wecook.gr από την ηλεκτρονική διεύθυνση επικοινωνίας την οποία έχετε δηλώσει, επισυνάπτοντας αντίγραφο της αστυνομικής σας ταυτότητας. Επίσης, έχετε το δικαίωμα να αναθεωρήσετε τα προσωπικά δεδομένα που τηρούμε και γενικά να ασκήσετε κάθε δικαίωμα που προβλέπεται από τη νομοθεσία για την προστασία δεδομένων προσωπικού χαρακτήρα.</p>

                <p>Tα προσωπικά δεδομένα που γνωστοποιείτε στην Wecook μέσω του wecook.gr είτε κατά την εγγραφή σας είτε και σε μεταγενέστερο στάδιο, συλλέγονται και τυγχάνουν χρήσης και επεξεργασίας σύμφωνα με τις εκάστοτε ισχύουσες διατάξεις περί προστασίας δεδομένων προσωπικού χαρακτήρα. και συγκεκριμένα, σύμφωνα με την τις διατάξεις του Ν. 2472/1997 και του Ν. 3471/2006, ως ισχύουν αλλά και του νέου Ευρωπαϊκού Γενικού Κανονισμού Προστασίας Δεδομένων (ΕΕ) 2016/679 καθώς και της οδηγίας 95/46/ΕΚ, περί προστασίας προσωπικών δεδομένων.</p>

                <div class="title-8">Διατηρείτε τα εξής δικαιώματα αναλυτικά:</div><br>
                <p>
                  <ul class="body-1">
                    <li>Δικαίωμα ενημέρωσης σχετικά με τα προσωπικά δεδομένα σας: Μετά από σχετικό αίτημά σας, θα σας παρέχουμε πληροφορίες σχετικά με τα προσωπικά δεδομένα που τηρούμε για εσάς.
                    </li>
                    <li>Δικαίωμα διόρθωσης και συμπλήρωσης των προσωπικών δεδομένων σας: Εφόσον μας ειδοποιήσετε σχετικά, θα διορθώσουμε τυχόν ανακριβή προσωπικά δεδομένα που σας αφορούν. Θα συμπληρώσουμε ατελή δεδομένα εφόσον μας ειδοποιήσετε σχετικά, υπό την προϋπόθεση ότι τα δεδομένα αυτά είναι αναγκαία για τους σκοπούς επεξεργασίας των δεδομένων σας.</li>
                    <li>Δικαίωμα για διαγραφή των προσωπικών δεδομένων σας: Μετά από σχετικό αίτημά σας, θα διαγράψουμε τα προσωπικά δεδομένα που τηρούμε για εσάς. Ωστόσο, ορισμένα δεδομένα θα διαγράφονται μόνο μετά από μια καθορισμένη περίοδο διακράτησης, για παράδειγμα επειδή σε ορισμένες περιπτώσεις υποχρεούμαστε εκ του νόμου να διατηρούμε τα δεδομένα, ή επειδή τα δεδομένα απαιτούνται για την εκπλήρωση των συμβατικών μας υποχρεώσεων απέναντι σε εσάς.</li>
                    <li>Δικαίωμα δέσμευσης των προσωπικών δεδομένων σας: Σε ορισμένες περιπτώσεις που προβλέπονται από το νόμο, θα δεσμεύσουμε τα δεδομένα σας, εάν μας το ζητήσετε. Περαιτέρω επεξεργασία δεσμευμένων δεδομένων γίνεται μόνο σε πολύ περιορισμένη έκταση. </li>
                    <li> Δικαίωμα ανάκλησης της συγκατάθεσής σας: Μπορείτε ανά πάσα στιγμή να ανακαλέσετε τη συγκατάθεσή σας για την επεξεργασία των προσωπικών δεδομένων σας στο μέλλον. Η νομιμότητα της επεξεργασίας των δεδομένων σας παραμένει ανεπηρέαστη από την ενέργεια αυτή, έως το σημείο ανάκλησης της συγκατάθεσής σας.</li>
                    <li>Δικαίωμά σας για προβολή αντιρρήσεων κατά της επεξεργασίας των δεδομένων σας: Μπορείτε ανά πάσα στιγμή να προβάλετε αντιρρήσεις κατά της επεξεργασίας των προσωπικών δεδομένων σας στο μέλλον, εάν επεξεργαζόμαστε τα δεδομένα σας στη βάση μίας εκ των νομικών αιτιολογήσεων που προβλέπονται στο άρθρο 6 (1ε ή 1στ) του Κανονισμού (ΕΕ) 2016/679. Εάν προβάλετε αντιρρήσεις, θα σταματήσουμε την επεξεργασία των δεδομένων σας, υπό την προϋπόθεση ότι δεν συντρέχουν νόμιμοι λόγοι για περαιτέρω επεξεργασία τους. Η επεξεργασία των δεδομένων σας για σκοπούς διαφήμισης δεν συνιστά νόμιμο λόγο.</li>
                    <li>Δικαίωμα καταγγελίας στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα: Μπορείτε ανά πάσα στιγμή να υποβάλλετε καταγγελία στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα (www.dpa.gr) στην περίπτωση που η επεξεργασία των δεδομένων σας γίνεται κατά παράβαση των διατάξεων του Κανονισμού (ΕΕ) 2016/679: Τα στοιχεία επικοινωνίας με την Αρχή είναι: Τηλεφωνικό Κέντρο: +30 210 6475600 | Fax: +30 210 6475628 | Ηλεκτρονικό Ταχυδρομείο: contact@dpa.gr</li>
                  </ul>
                </p>
              </li>
              <li>
                <div class="title-8">Διαβίβαση Προσωπικών Δεδομένων</div><br>
                <p>Η Wecook δεν μοιράζεται ούτε διαβιβάζει προσωπικά δεδομένα με τρίτους, εκτός αν κάτι τέτοιο απαιτείται για τους νόμιμους επαγγελματικούς σκοπούς και τις επιχειρηματικές ανάγκες, προκειμένου να ανταποκριθεί στα αιτήματά σας ή/και εφόσον επιβάλλεται ή επιτρέπεται από το νόμο. Σε κάθε περίπτωση, η πρόσβαση στα προσωπικά σας δεδομένα επιτρέπεται μόνο σε εξουσιοδοτημένα πρόσωπα τα οποία απαιτείται να έχουν πρόσβαση προκειμένου να είναι δυνατή η ολοκλήρωση των σκοπών της συλλογής, χρήσης και επεξεργασίας τους, όπως αυτή γνωστοποιείται με την παρούσα Δήλωση.</p>

                <p>Σε ορισμένες περιπτώσεις, η Wecook’ s ενδέχεται να μοιραστεί τα προσωπικά σας δεδομένα με διάφορες εταιρίες ή παρόχους υπηρεσιών που συνεργάζονται μαζί της, προκειμένου να ανταποκριθεί στα αιτήματά σας ή σε φυσικά ή νομικά πρόσωπα στα οποία έχει ανατεθεί η εκτέλεση της επεξεργασίας, υπό την προϋπόθεση ότι θα σας ενημερώσουμε εκ των προτέρων και θα λάβουμε την προηγούμενη συναίνεσή σας. Τα άτομα που έχουν πρόσβαση στα δεδομένα είναι υποχρεωμένα να τηρούν την εμπιστευτικότητα αυτών των δεδομένων. </p>

                <p>Η Wecook επιφυλάσσεται του δικαιώματος της να χρησιμοποιήσει τα στοιχεία που της γνωστοποιείτε μέσω του www.Wecook.gr οπως και της εφαρμογής κατά τρόπο που να μην είναι δυνατή η ταυτοποίηση ή η αποκάλυψη του προσώπου που αυτά αφορούν, για στατιστικούς, προωθητικούς, ερευνητικούς ή και διαφημιστικούς σκοπούς, γνωστοποιώντας αυτά και σε τρίτα πρόσωπα π.χ. εταιρείες ερευνών αρμόδια για την ολοκλήρωση των ανωτέρω σκοπών. </p>

                <p>Η Wecook ενδέχεται να μεταφέρει ορισμένα προσωπικά δεδομένα εκτός γεωγραφικών συνόρων σε άλλες εταιρίες που συνεργάζονται με αυτή ή/και να αποθηκεύει προσωπικά δεδομένα σε κάποια δικαιοδοσία διαφορετική από αυτήν όπου βρίσκεστε. Με το να παρέχουν τα προσωπικά δεδομένα τους μέσω διαδικτύου, οι επισκέπτες συναινούν στη μεταφορά αυτή ή/και στην αποθήκευση των προσωπικών τους δεδομένων στο εξωτερικό.</p>

                <p>Η Wecook ενδέχεται να αποθηκεύει τα προσωπικά σας δεδομένα σε cloud. Αυτό σημαίνει ότι η επεξεργασία των προσωπικών σας δεδομένων μπορεί να γίνεται από μια υπηρεσία παροχής υπηρεσιών cloud για λογαριασμό της Wecook και τα προσωπικά σας δεδομένα μπορεί να αποθηκεύονται σε διαφορετικές τοποθεσίες ανά τον κόσμο. Η Εταιρία χρησιμοποιεί οργανωτικά και συμβατικά μέτρα για την προστασία των προσωπικών δεδομένων και για την επιβολή παρόμοιων, αλλά σε καμία περίπτωση λιγότερο περιοριστικών, απαιτήσεων στους παρόχους μας υπηρεσιών cloud, συμπεριλαμβάνοντας απαιτήσεις σχετικά με την αποκλειστική επεξεργασία των προσωπικών σας δεδομένων για τους στην παρούσα Δήλωση αναφερθέντες σκοπούς.</p>
              </li>
              <li>
                <div class="title-8">Ασφάλεια των Προσωπικών Δεδομένων</div><br>
                <p>Η Wecook εφαρμόζει συγκεκριμένες διαδικασίες τεχνικής και οργανωτικής ασφαλείας, προκειμένου να προστατεύει τα προσωπικά δεδομένα και πληροφορίες από απώλεια, κακή χρήση, μεταβολή ή καταστροφή. Οι συνεργάτες μας που μας υποστηρίζουν στη λειτουργία αυτού του διαδικτυακού τόπου επίσης συμμορφώνονται με αυτές τις διατάξεις.</p> 

                <p>Η Wecook καταβάλλει κάθε εύλογη προσπάθεια να τηρεί τα προσωπικά δεδομένα που συλλέγονται μόνο για το χρονικό διάστημα για το οποίο χρειάζεται τα δεδομένα αυτά για τον σκοπό για τον οποίο συνελέγησαν ή μέχρις ότου ζητηθεί η διαγραφή τους (εάν αυτό συμβεί νωρίτερα), εκτός εάν συνεχίσει να τα τηρεί κατά τα προβλεπόμενα στην κείμενη νομοθεσία.</p>
              </li>
              <li>
                <div class="title-8">Αναθεωρήσεις της Δήλωσης</div><br>
                <p>Η Wecook διατηρεί το δικαίωμα να τροποποιεί ή να την αναθεωρεί περιοδικά την παρούσα Δήλωση, κατά την απόλυτη διακριτική της ευχέρεια. Σε περίπτωση που πραγματοποιηθούν αλλαγές, η Wecook θα καταγράφει την ημερομηνία τροποποίησης ή αναθεώρησης στην παρούσα Δήλωση και η επικαιροποιημένη Δήλωση θα ισχύει για εσάς από την ημερομηνία αυτή. Σας ενθαρρύνουμε να μελετάτε κατά περιόδους την παρούσα Δήλωση προκειμένου να εξετάζετε εάν υπάρχουν τυχόν αλλαγές στον τρόπο με τον οποίο διαχειριζόμαστε τα προσωπικά σας δεδομένα. Η παρούσα Δήλωση ενημερώθηκε για τελευταία φορά την 26η Μαιόυ 2023.</p>
              </li>
            </ol>
          </v-col>

          <v-col cols="12">
            <div class="title-8">Πολιτική Cookies</div>
            <p class="body-1">Η πολιτική αυτή εξηγεί το πώς η Wecook χρησιμοποιεί τα Cookies κατά την περιήγησή σας στο Wecook.gr και στην mobile εφαρμογή μας σε mobile και tablet συσκευές. Με την αποδοχή της Πολιτικής αυτής επιβεβαιώνετε πως έχετε αποδεχθεί και έχετε ενημερωθεί για την χρησιμοποίηση των Cookies μας στον browser που χρησιμοποιείτε</p>
          </v-col>

          <v-col cols="12">
            <div class="title-8">Τι είναι τα Cookies;</div>
            <p class="body-1">Τα cookies είναι μικρά αρχεία δεδομένων αποτελούμενα από κείμενα και αριθμούς που αποθηκεύονται στον browser που χρησιμοποιείτε (Chrome, Mozilla Firefox, Internet Explorer) που περιέχουν πληροφορίες που επιτρέπουν στο διαδικτυακό τόπο να απομνημονεύει τις σημαντικές πληροφορίες που θα κάνουν την χρήση του www.Wecooks.gr πιο αποτελεσματική και χρήσιμη για εσάς. Τα cookies μας επιτρέπουν να αναγνωρίζουμε την συσκευή σας και να ενεργοποιούμε συγκεκριμένες πληροφορίες που κάνουν την πλοήγησή σας πιο εύκολη, όπως το κατάστημα που σας εξυπηρετεί. Τα cookies σας βοηθούν ώστε να διατηρήσετε τις προσωπικές σας ρυθμίσεις και μας βοηθούν να διαμορφώσουμε το site μας σύμφωνα με αυτές.</p>
          </v-col>

          <v-col cols="12">
            <div class="title-8">Μπορώ να μην δεχθώ την χρήση Cookies;</div>
            <p class="body-1">Μπορείτε να επιλέξετε να απενεργοποιήσετε τα cookies μέσα από τις ρυθμίσεις του browser σας καθώς και διαγράψετε όλα τα cookies που έχουν αποθηκευτεί πρόσφατα στον browser. Μπορείτε να βρείτε περισσότερες οδηγίες πατώντας Βοήθεια στο menu του browser. Ωστόσο, θα πρέπει να γνωρίζετε πως απενεργοποιώντας τα cookies από τον browser σας, μπορεί να μην σας επιτρέπει να αξιοποιήσετε όλες τις δυνατότητες του site και ορισμένες από τις λειτουργίες του site μπορεί να μην λειτουργήσουν όπως θα έπρεπε καθώς βασίζουν την λειτουργία τους στα cookies.</p>
          </v-col>

          <v-col cols="12">
            <div class="title-8">Χρήση Cookies από το Wecooks.gr</div>
            <p class="body-1">Στο Wecook.gr χρησιμοποιούμε Cookies, χωρίς ωστόσο να συγκεντρώνουμε, να επεξεργαζόμαστε ή να αποθηκεύουμε προσωπικά δεδομένα από την χρήση του. Χρησιμοποιούμε την τεχνολογία των cookies και τις διευθύνσεις IP για να λαμβάνουμε μη προσωπικά δεδομένα από τους επισκέπτες μας και για να προσφέρουμε στους εγγεγραμμένους μας επισκέπτες την καλύτερη δυνατή εξατομικευμένη online εμπειρία. Με την περιήγηση στο site μας συγκεντρώνουμε απλές πληροφορίες αναγνώρισης και μετρήσεων, όπως για παράδειγμα το πόσοι χρήστες επισκέφθηκαν συγκεκριμένα σημεία του site μας. Η χρήση των cookies δεν βλάπτει σε καμία περίπτωση τον υπολογιστή σας ή την mobile συσκευή σας. Τα cookies μπορούν να χρησιμοποιηθούν και για επαναληπτικό marketing στο διαδίκτυο.</p>
          </v-col>

          <v-col cols="12">
            <div class="title-8">Cookies Αρχικού Κατασκευαστή</div>
            <p class="body-1">Τα cookies Αρχικού Κατασκευαστή είναι cookies που χρησιμοποιεί είτε το Wecooks.gr είτε από τρίτα μέρη που χρησιμοποιούν το domain του site μας. Για παράδειγμα το Wecook.gr χρησιμοποιεί cookies από το Google Analytics.</p>
          </v-col>

          <v-col cols="12">
            <div class="title-8">Cookies Τρίτου Μέρους</div>
            <p class="body-1">Τα Cookies τρίτου μέρους δημιουργούνται στον browser σας από διαφορετικό site από το site στο οποίο περιηγήστε (σε αυτή την περίπτωση διαφορετικό από το Wecook.gr). Τέτοια Cookies μπορεί να είναι cookies από το DoubleClick για την αξιολόγηση των ενεργειών marketing μέσω τρίτων sites Επιτρέπεται να επεξεργαζόμαστε δεδομένα σε αυτό το τεχνολογικό πλαίσιο βάσει του νόμου (άρθρο 6(1στ) του Κανονισμού (ΕΕ) 2016/679). Αποθηκεύουμε αυτά τα δεδομένα, έως ότου το εκάστοτε cookie λήξει ή το διαγράψετε.</p>
          </v-col>

          <v-col cols="12">
            <div class="title-8">Επικοινωνία</div>
            <p class="body-1">Εάν έχετε ερωτήσεις, σχόλια ή καταγγελίες σχετικά με την διαχείριση ή την προστασία από εμάς των προσωπικών σας δεδομένων ή εάν επιθυμείτε να τροποποιήσετε τα προσωπικά σας δεδομένα ή να εξασκήσετε οποιοδήποτε δικαίωμά σας ως υποκείμενο των δεδομένων, παρακαλείστε να επικοινωνήσετε μαζί μας στη διεύθυνση info@wecook.gr.</p>

            <p>Οι όροι "εμείς" και “Wecook” “εταιρία” αναφέρονται στην Ανώνυμη Εταιρεία με την επωνυμία WE COOK A.E., που εδρεύει στη Αθήνα, στην οδό Εσλιν 21 , Τ.Κ. 11523, με Α.Φ.Μ 801645717</p>
          </v-col>
        </v-row>
      </div>

      <SignInModal
        :visible="showSignInModal"
        @close="showSignInModal = false"
      />

      <NavigationDrawer
        :visible="showNavDrawer"
        @close="showNavDrawer = false"
        @open-sign-in-modal="openSignInModal"
      />
    </v-container>
  </div>
</template>

<script>
  import NavigationDrawer from '@/components/Navigation/NavigationDrawer';
  import SignInModal from '@/components/Auth/SignInModal.vue';

  export default {
    name: 'TermsAndConditions',
    components: {
      NavigationDrawer,
      SignInModal
    },
    data() {
      return {
        showSignInModal: false,
        showNavDrawer: false,
        menu_icon: "$vuetify.icons.menu_icon",
      };
    },
    methods: {
      openSignInModal() {
        this.showNavDrawer = false;
        this.showSignInModal = true;
      }
    }
  };
</script>

<style type="text/css" scoped>
  #terms-and-conditions ul li,
  #terms-and-conditions ol li {
    padding: 4px 0;
  }
</style>